import { combineReducers } from "redux";

import ArchiveSlice from "./features/AdminPanel/Archives/ArchiveSlice";
import authorSlice from "./features/AdminPanel/Blog/Authors/AuthorSlice";
import blogSlice from "./features/AdminPanel/Blog/BlogSlice";
import categorySlice from "./features/AdminPanel/Blog/Category/CategorySlice";
import adminLearningPathSlice from "./features/AdminPanel/LearningPath/LearningPathSlice";
import learningPathCardSlice from "./features/AdminPanel/LearningPathCard/LearningPathCardSlice";
import manageCourseSlice from "./features/AdminPanel/ManageCourse/ManageCourseSlice";
import manageInstructorSlice from "./features/AdminPanel/ManageInstructor/ManageInstructorSlice";
import OnboardingSlice from "./features/AdminPanel/Onboarding/OnboardingSlice";
import PaymentSlice from "./features/AdminPanel/Payment/PaymentSlice";
import resourceManagerSlice from "./features/AdminPanel/ResourceManager/ResourceManagerSlice";
import aboutSlice from "./features/AdminPanel/Setting/About/AboutSlice";
import privacySlice from "./features/AdminPanel/Setting/Privacy/PrivacySlice";
import termsOfuseSlice from "./features/AdminPanel/Setting/Terms/TermsSlice";
import sprintSlice from "./features/AdminPanel/Sprint/SprintSlice";
import subscriberSlice from "./features/AdminPanel/Subscribers/SubscriberSlice";
import ourTeamsSlice from "./features/AdminPanel/teams/ourTeamsSlice";
import tenantSlice from "./features/AdminPanel/TenantTeams/TenantTeamSlice";
import testimonialsSlice from "./features/AdminPanel/Testimonial/TestimonialSlice";
import tutionPlansSlice from "./features/AdminPanel/TutionPlans/TutionPlanSlice";
import userSlice from "./features/AdminPanel/User/UserSlice";
import viewCoursesSlice from "./features/AdminPanel/ViewCourses/ViewCoursesSlice";
import authSlice from "./features/Auth/AuthSlice";
import siteSettingsSlice from "./features/Site/SiteSlice";
import assismentSlice from "./features/Tenant/Orgination/Assisment/ManageAssismentSlice";
import tanentCoursesSlice from "./features/Tenant/Orgination/Courses/CourseSlice";
import dashboardSlice from "./features/Tenant/Orgination/Dashboard/DashboardSlice";
import groupSlice from "./features/Tenant/Orgination/Group/GroupSlice";
import tenantLearningPathSlice from "./features/Tenant/Orgination/LearningPath/TenantLearningPathSlice";
import memberSlice from "./features/Tenant/Orgination/Member/MemberSlice";
import tenantUserCoursesSlice from "./features/Tenant/User/Courses/CoursesSlice";
import tenantUserDashboardSlice from "./features/Tenant/User/Dashboard/DashboardSlice";
import tenantUserLearningPathSlice from "./features/Tenant/User/LearningPath/LearningPathSlice";
import themeSlice from "./features/ThemeSlice";
import thinkificSlice from "./features/Thinkific/ThinkfiiSlice";
import UISlice from "./features/UI/UISlice";
import classArchiveSlice from "./features/UserDashboard/Archives/ArchiveSlice";
import certificateSlice from "./features/UserDashboard/Certificate/CertificateSlice";
import courseDetailSlice from "./features/UserDashboard/courseDetail/CourseDetailSlice";
import coursesSlice from "./features/UserDashboard/Courses/CoursesSlice";
import faqSlice from "./features/UserDashboard/Faq/FaqSlice";
import ourClassSlice from "./features/UserDashboard/OurClasses/OurClassSlice";
import learningPathSlice from "./features/UserDashboard/LearningPath/LearningPathSlice";
import progressSlice from "./features/UserDashboard/MyProgress/MyProgressSlice";
import newUserCoursesSlice from "./features/UserDashboard/NewUserCoursesAndLearningPath/NewUserCoursesAndLearningPathSlice";
import UserApplicationTracker from "./features/UserDashboard/NewUserDashboard/ApplicationTracker/ApplicationTrackerSlice";
import newDashboard from "./features/UserDashboard/NewUserDashboard/NewUserDashboardSlice";
import QuickLinksSlice from "./features/UserDashboard/QuickLinks/QuickLinksSlice";
import ReferenceSlice from "./features/UserDashboard/Reference/ReferenceSlice";
import userResourceSlice from "./features/UserDashboard/Resources/UserResourceSlice";
import showCourseSlice from "./features/UserDashboard/ShowCourse/ShowCourseSlice";
import weeklySlice from "./features/AdminPanel/WeeklyLearning/WeeklyLearningSlice";
import interviewPreprationSlice from "./features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationSlice";
import aiSlice from "./features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import interviewSlice from "./features/AdminPanel/Interview/Question/InterviewSlice";
import interviewAnswerSlice from "./features/AdminPanel/Interview/Answer/InterviewAnswerSlice";
import WebinarSlice from "./features/AdminPanel/Webinar/WebinarSlice";
import mentorSlice from "./features/AdminPanel/Mentor/MentorSlice";
import NotificationSlice from "./features/Notifications/NotificationSlice";
import InterviewAndWebminarSlice from "./features/UserDashboard/InterviewAndWebminar/InterviewAndWebminarSlice";
import TACPaymentSlice from "./features/UserDashboard/Payment/PaymentSlice";
import TACProductManagerSlice from "./features/ProductManagerUser/ProductManagerCourses/ProductManagerCoursesSlice";
import InterviewDetailsSlice from "./features/UserDashboard/InterviewDetails/InterviewDetails.slice";
import DateTimeSlice from "./features/AdminPanel/DateTime/DateTimeSlice";
import applyNowSlice from "./features/AdminPanel/ApplyNow/ApplyNowSlice";
import siteManagerSlice from "./features/AdminPanel/SiteManager/SiteManagerSlice";
import showOnboardingPopupSlice from "./features/UserDashboard/ShowOnboardingPopup/ShowOnboardingPopupSlice";
import meetWithUsSlice from "./features/AdminPanel/MeetWithUs/MeetWithUsSlice";
import meetWithTeamSlice from "./features/UserDashboard/NewUserDashboard/MeetWithTeam/MeetWithTeamSlice";
import subAdminSlice from "./features/AdminPanel/SubAdmin/SubAdminSlice";
import ConnectWithGoHighlevelSlice  from "./features/AdminPanel/ConnectWithGoHighlevel/ConnectWithGoHighlevelSlice";
import videoTourSlice from "./features/AdminPanel/VideoTour/VideoTourSlice";

const RootReducer = combineReducers({
  AuthSlice: authSlice,
  FaqSlice: faqSlice,
  OurClassSlice: ourClassSlice,
  UserSlice: userSlice,
  CoursesSlice: coursesSlice,
  ShowCourseSlice: showCourseSlice,
  LearningPathSlice: learningPathSlice,
  ProgressSlice: progressSlice,
  AdminLearningPathSlice: adminLearningPathSlice,
  ManageCourseSlice: manageCourseSlice,
  ManageInstructorSlice: manageInstructorSlice,
  BlogSlice: blogSlice,
  ThemeSlice: themeSlice,
  LearningPathCardSlice: learningPathCardSlice,
  ViewCoursesSlice: viewCoursesSlice,
  OurTeamsSlice: ourTeamsSlice,
  TermsOfuseSlice: termsOfuseSlice,
  PrivacySlice: privacySlice,
  AboutSlice: aboutSlice,
  CertificateSlice: certificateSlice,
  AuthorSlice: authorSlice,
  CategorySlice: categorySlice,
  ResourceManagerSlice: resourceManagerSlice,
  UserResourceSlice: userResourceSlice,
  SiteSettingsSlice: siteSettingsSlice,
  TestimonialsSlice: testimonialsSlice,
  GroupSlice: groupSlice,
  MemberSlice: memberSlice,
  DashboardSlice: dashboardSlice,
  AssismentSlice: assismentSlice,
  TenantLearningPathSlice: tenantLearningPathSlice,
  SubscriberSlice: subscriberSlice,
  TutionPlansSlice: tutionPlansSlice,
  TanentCoursesSlice: tanentCoursesSlice,
  TenantUserDashboardSlice: tenantUserDashboardSlice,
  TenantUserCourseSlice: tenantUserCoursesSlice,
  TenantUserLearningPathSlice: tenantUserLearningPathSlice,
  TenantTeamSlice: tenantSlice,
  AdminPaymentSlice: PaymentSlice,
  ArchiveSlice: ArchiveSlice,

  OnboardingSlice: OnboardingSlice,
  ClassArchiveSlice: classArchiveSlice,
  NewDashboard: newDashboard,
  ThinkificSlice: thinkificSlice,
  SprintSlice: sprintSlice,

  QuickLinksSlice: QuickLinksSlice,
  ReferenceSlice: ReferenceSlice,
  CourseDetailSlice: courseDetailSlice,
  NewUserCoursesAndLearningPathSlice: newUserCoursesSlice,
  UserApplicationTracker: UserApplicationTracker,
  WeeklySlice: weeklySlice,
  InterviewPreprationSlice: interviewPreprationSlice,
  AiSlice: aiSlice,
  InterviewSlice: interviewSlice,
  InterviewAnswerSlice: interviewAnswerSlice,
  WebinarSlice: WebinarSlice,
  MentorSlice: mentorSlice,

  UISlice: UISlice,
  NotificationSlice: NotificationSlice,
  InterviewAndWebminarSlice: InterviewAndWebminarSlice,
  TACPaymentSlice: TACPaymentSlice,

  TACProductManagerSlice: TACProductManagerSlice,

  InterviewDetailsSlice,
  DateTimeSlice: DateTimeSlice,
  ApplyNowSlice: applyNowSlice,

  SiteManagerSlice: siteManagerSlice,
  ShowPopupSlice: showOnboardingPopupSlice,
  MeetWithUsSlice: meetWithUsSlice,
  MeetWithTeamSlice: meetWithTeamSlice,
  SubAdminSlice: subAdminSlice,
  ConnectWithGoHighlevelSlice:ConnectWithGoHighlevelSlice,
  VideoTourSlice:videoTourSlice
});

export default RootReducer;
