import { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import AdminPanelHeader from "../AdminPanelHeader/AdminPanelHeader";
import AdminPanelSidebar from "../AdminPanelSidebar/AdminPanelSidebar";
import "react-toastify/dist/ReactToastify.css";
import "./adminPanelLayout.scss";
import { RootStore, useAppSelector } from "../../../store/store";

function AdminPanelLayout(props: any) {
  const { children } = props;
  const [showSidebarInSmDevice, setShowSidebarInSmDevice] = useState(false);
  const [smSidebar, setSmSidebar] = useState(false);

      //* **** FOR Admin PROFILE DETECT OUTSIDE CLICK**************** */
      const mobileSidebarRef: any = useRef(null);
      const iconRef: any = useRef(null);

      const configState: any = useAppSelector((store: RootStore) => {
        return store.ThemeSlice;
      });
    
      const handleClickOutSideAdminPannelSidebar = (event: any) => {
        if (
          mobileSidebarRef.current && !mobileSidebarRef.current.contains(event.target)
        ) {
          if (iconRef.current && !iconRef.current.contains(event.target)) {
            setShowSidebarInSmDevice(false);
          }
        }
      };
      useEffect(() => {
        document.addEventListener("mousedown", handleClickOutSideAdminPannelSidebar);
      }, []);

  return (
    <div className="adminPanelLayout__container">
      <div className="sidebar">
        <AdminPanelSidebar smSidebar={smSidebar} setSmSidebar={setSmSidebar} showSidebarInSmDevice={showSidebarInSmDevice} setShowSidebarInSmDevice={setShowSidebarInSmDevice} mobileSidebarRef={mobileSidebarRef} />
      </div>
      <div
        className={`${
          smSidebar ? "content__container sm" : "content__container"
        }`}
      >
        <div className="header__section">
          <AdminPanelHeader smSidebar={smSidebar} setSmSidebar={setSmSidebar} showSidebarInSmDevice={showSidebarInSmDevice} setShowSidebarInSmDevice={setShowSidebarInSmDevice}  iconRef={iconRef}/>
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover  
          />
        </div>
        <div className={`content__section ${configState?.configs?.tenant_name === null ? "" : "mt-7"}`}>{children}</div>
      </div>
    </div>
  );
}

export default AdminPanelLayout;
