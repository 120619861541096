/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable new-cap */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

import moment from "moment";

import "../../../../shared/Table/table.scss";
import "./index.scss";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import {
  PrimaryButton,
  PrimaryTextButton,
  TACPrimaryButton,
} from "../../../../shared/Buttons/Buttons";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { ListAllCertificate } from "../../../../store/features/UserDashboard/Certificate/CertificateApi";
import Modal from "../../../../shared/Modals/Modal";

import Certificate from "./Certificate/Certificate";
import CustomEmptyComp from "../../../../shared/Empty/component/CustomEmptyComp";
import CertificateTemplate from "../../../Certificate/CertificateTemplate";
import { searchCertificates } from "../../../../store/features/UserDashboard/Certificate/CertificateSlice";

const CertificatesTable = () => {
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");

  const certificateState: any = useAppSelector((store: RootStore) => {
    return store.CertificateSlice;
  });

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});

  const pdfRef = useRef<any>();

  useEffect(() => {
    dispatch(ListAllCertificate());
  }, []);

  useEffect(() => {
    dispatch(searchCertificates(searchText));
  }, [searchText]);

  const openCertificate = (certificate: any) => {
    setData(certificate);
    setShowModal(true);
  };

  return (
    <>
      <div className="table__container certificate_table_container mt-7">
        <div className="table-top">
          <div className="tt-title">My Certificates</div>

          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="table custom-certi-table">
          <table>
            <thead>
              <tr className="table-head h-44px" style={{ background: "red" }}>
                <th className="course">Course</th>
                <th className="completed">Completed At</th>
                <th className="action opacity-0">Action</th>
              </tr>
            </thead>
            <tbody>
              {certificateState.req_success &&
                certificateState.certificates?.length > 0 &&
                certificateState.certificates.map((certificate: any) => {
                  return (
                    <tr key={certificate.id}>
                      <td>{certificate.course_name}</td>
                      <td>{moment(certificate.completed_at).format("ll")}</td>
                      <td className="action certi__button">
                        <TACPrimaryButton
                          text="View Certificate"
                          onClick={() => openCertificate(certificate)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {certificateState.req_success && (
        <CustomEmptyComp
          search={
            searchText.length > 1 && certificateState.certificates.length === 0
          }
          empty={certificateState.certificates.length === 0}
          tac
        />
      )}
      <Modal show={showModal} setShow={setShowModal}>
        <div className="certificate_view_container">
          <div className="cv-btn-container">
            <PDFDownloadLink
              document={<CertificateTemplate certificate={data} />}
              fileName="certificate.pdf"
            >
              {({ blob, url, loading, error }) => (
                <button type="button" className="secondary">
                  {loading ? "Loading pdf..." : "Download"}
                </button>
              )}
            </PDFDownloadLink>
          </div>
          <div className="certificate-view">
            <Certificate certificate={data} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CertificatesTable;
