import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";

import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { FeedbackValues } from "./types";

export const getRecentlyAnsweredInterviews = createAsyncThunk(
  "getRecentlyAnsweredInterviews",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res =
        await axiosInstance.get(`${dynamic_url}/interview/recently-answered
      `);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRecentShareLink = createAsyncThunk(
  "updateRecentShareLink",
  async (
    {
      videoId
    }: { videoId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.post(
        `${dynamic_url}/generateVideoUrl/${videoId}`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRecentlyAnsweredDetails = createAsyncThunk(
  "getRecentlyAnsweredDetails",
  async (id: number | string, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res =
        await axiosInstance.get(`${dynamic_url}/interview/recently-answered/${id}
      `);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteInterviewAnswerVideo = createAsyncThunk(
  "deleteInterviewAnswerVideo",
  async (id: number | string, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res =
        await axiosInstance.delete(`${dynamic_url}/delete-answer-video/${id}
      `);
      toast.success(res?.data?.message);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteInterviewAnswerFeedback = createAsyncThunk(
  "deleteInterviewAnswerFeedback",
  async (id: number | string, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res =
        await axiosInstance.delete(`${dynamic_url}/delete-answer-feedback/${id}
      `);

      toast.success(res?.data?.message);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createInterviewDetailsFeedback = createAsyncThunk(
  "createInterviewDetailsFeedback",
  async (
    data: { body: FeedbackValues; id: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/submit-answer-feedback/${data.id}
    `,
        data.body
      );

      toast.success(res?.data?.message);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
