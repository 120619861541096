import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./index.scss";
import blackUploadIcon from "../../../../../../assets/images/upload-black.svg";
import whiteUploadIcon from "../../../../../../assets/images/upload-white.svg";
import { RootStore, useAppDispatch, useAppSelector } from "../../../../../../store/store";
import UploadBulkArchiveFileuploadProgressbar from "../UploadBulkArciveFileUploadProgressbar/UploadBulkArchiveFileuploadProgressbar";
import { UploadArchiveCSVFile } from "../../../../../../store/features/AdminPanel/Archives/ArchiveApi";


const UploadBulkArchiveFileUploader = (props: any) => {
  const { fileUploadError, SetFileUpladError, progress, setProgress } = props;
  const dispatch = useAppDispatch();

  const progressRef: any = useRef();

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const archiveState: any = useAppSelector((store: RootStore) => {
    return store.ArchiveSlice;
  });

  const onDrop = useCallback((files: any) => {
    setProgress({ name: "", progress: 0, id: 0 });
    SetFileUpladError({});
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("file", files[0]);
     
      dispatch(
        UploadArchiveCSVFile({
          file: formData,
          name: files[0].name,
          setProgress: setProgress,
          SetFileUpladError: SetFileUpladError,
        })
      );
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/html": [".csv"],
    },
    multiple: false,
    disabled: archiveState.uploaderLoading,
  });

  useEffect(() => {
    if (archiveState.clean_upload_screen) {
      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
    }
  }, [archiveState.clean_upload_screen]);

  return (
    <div className="file__upload__container padding">
      <div className="uploader__card " {...getRootProps()}>
        <div className="circle_bg">
          <img
            src={
              themeState.themeMode === "dark"
                ? blackUploadIcon
                : whiteUploadIcon
            }
            alt="upload"
          />
        </div>

        <input {...getInputProps()} id="upl" multiple={false} />

        <span className="upload__description mt-1">
          Drag and drop files, or browse
        </span>
        <span className="upload__description"> Supports CSV files only.</span>
      </div>
      <span className="uploader__File__counter m-1">Uploading Files</span>

      <div className="progress_section">

        {acceptedFiles.map((file) => {
          return (
        <UploadBulkArchiveFileuploadProgressbar  
              key={file.name}
              progress={progress.progress}
              file={file}
              acceptedFiles={acceptedFiles}
              fileUploadError={fileUploadError}
              progressRef={progressRef}
              SetFileUpladError={SetFileUpladError}
        />
        );
      })} 
      </div>
    </div>
  );
};

export default UploadBulkArchiveFileUploader
