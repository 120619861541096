import React, { memo, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../../shared/Modals/Modal";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  clearStripe,
  closeEditUserPayment,
} from "../../../../../store/features/UserDashboard/Payment/PaymentSlice";
import { checkUserPricing } from "../../../../../store/features/UserDashboard/Payment/PaymentApi";
import { stripeKey } from "../../../../../apiConfigs/StripeKey";
import CheckoutForm from "../CheckoutForm/CheckoutForm";

const stripePromise: any = loadStripe(stripeKey());

const BillingCard = (porps: any) => {
  const { showModal, setShowModal, tenant } = porps;
  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.TACPaymentSlice;
  });
  const tenantData: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice.tenant_pay_details;
  });

  const dispatch = useAppDispatch();
  const handleModal = () => {
    dispatch(clearStripe());
    dispatch(closeEditUserPayment());
  };

  useEffect(() => {
    const data: any = "";
    if (!tenant) {
      console.log("fired-1");
      dispatch(checkUserPricing({ data }));
    }
  }, []);

  return (
    <Modal show={showModal} setShow={setShowModal} onClick={handleModal}>
      {!tenant ? (
        <ModalHeader
          title={
            paymentState.edit_payment
              ? "Update Payment Method"
              : " Upgrade Account"
          }
        />
      ) : (
        <ModalHeader title="Upgrade Account" />
      )}
      <ModalBody>
        <Elements stripe={stripePromise}>
          <CheckoutForm
            setShowModal={setShowModal}
            tenant={tenant}
            size={tenantData?.seat_count}
            billingType={tenantData?.billing_type}
          />
        </Elements>
      </ModalBody>
    </Modal>
  );
};

export default memo(BillingCard);
